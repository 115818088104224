import {ApiRes} from '@type/api'
import {apiPost} from '@api/api'
import {ILoginResV2} from '@type/auth'

export const apiConfirmPasswordResetCode = async (
    email: string,
    code: string,
    password: string,
): Promise<ApiRes<ILoginResV2>> => {
    return await apiPost(`password/reset`, {email: email, code: code, password: password})
}

import {apiGet, apiPost} from '@api/api'
import {ILoginEmailCheckRes, ILoginRes, ILoginResV2} from '@type/auth'
import {ApiRes} from '@type/api'
import {IConfirmResponse} from '@api/auths/register/Register'
import {safeReturn} from '@util/etc'

export const apiLogin = async (email: string, password: string): Promise<ApiRes<ILoginRes>> => {
    return await apiPost(`login/email`, {email: email, password: password})
}

export const apiLoginNetwork = async (
    network: string,
    accessToken: string,
    code: string = '',
): Promise<ApiRes<ILoginRes>> => {
    return await apiPost(`login/${network}`, {access_token: accessToken, code: code})
}

export const apiSendForgotPasswordEmail = async (email: string): Promise<ApiRes<IConfirmResponse>> => {
    return await apiGet(`forgotpass/${email}`)
}

export const apiResetPassword = async (token: any, password: string): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost(`forgotpass`, {token: token, password: password})
}

export const apiCheckEmailLogin = async (email: string): Promise<ApiRes<ILoginEmailCheckRes>> => {
    return await apiPost(`signin/email-check`, {email: email})
}

export const apiLoginEmail = async (email: string, password: string): Promise<ApiRes<ILoginRes>> => {
    return await apiPost(`signin/email`, {email: email, password: password})
}

export const apiLoginSns = async (
    network: string,
    accessToken: string,
    code: string = '',
    referral?: string,
): Promise<ApiRes<ILoginResV2>> => {
    return await apiPost(`signin/${network}`, {
        access_token: accessToken,
        code: safeReturn(code),
        referral: safeReturn(referral),
    })
}

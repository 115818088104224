import {ApiRes} from '@type/api'
import {apiGet, apiPost} from '@api/api'
import {IConfirmResponse} from '@api/auths/register/Register'

export interface ICheckUserNameRes {
    can_use: boolean
}

export const apiCheckUserName = async (userName: string): Promise<ApiRes<ICheckUserNameRes>> => {
    return await apiGet(`username/${userName}`)
}

import {ApiRes} from '@type/api'
import {apiPost} from '@api/api'
import {ILoginResV2} from '@type/auth'
import {safeReturn} from '@util/etc'

export const apiEmailConfirmCode = async (
    email: string,
    name: string,
    code: string,
    referral?: string,
): Promise<ApiRes<ILoginResV2>> => {
    return await apiPost('signin/email', {
        email: email,
        name: name,
        code: code,
        referral: safeReturn(referral),
    })
}

import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

export interface ICheckReferralRes {
    is_valid: boolean
}

export const apiCheckReferral = async (referralCode: string): Promise<ApiRes<ICheckReferralRes>> => {
    return await apiGet(`user/referral/check/${referralCode}`)
}
